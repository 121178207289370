import portal_config from "@/configs/portal"
import storeActivate from "@/helpers/store/storeActivateHelper"
import storeAuth from "@/helpers/store/storeAuthHelper"
import commonApi from "@/helpers/common/common_api"
import { getRouterPolicy } from "@/helpers/common/route";
import { isEnabledFunctionPolicy } from "@/helpers/common/role";
import { isSystemApp, isDisplayModeSP, getRouteName, isVManageUsingShop } from "@/helpers/common/common_util"
import { isProxySigninAccount, isEnabledTimemng, isUserHeadShop, isAddSetupRequest, isUserShopAccount } from "@/helpers/auth/auth_util"
import { checkInviteQr, portalMenuMove } from "@/helpers/portal/portal_util"
import { isEnvProd } from "@/helpers/environment"
import { errorLog, debugLog, infoLog } from '@/helpers/common/datadog'
// import { readNotificationsList } from "@/helpers/common/notifications"
import {
  apiServiceLinksCompany, apiServiceLinksAssociate,
  apiServiceLinksPreviewIcon,
} from "@/helpers/portal/portal_api";
import { apiParallelCall } from '@/helpers/api_common'

// 作成時初期設定
export async function initCreated(self) {
  if (!isSystemApp()) {
    let SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) self.recognition = new SpeechRecognition();
  }
  readInviteError(self)
}

// 描画時初期設定
export async function initMounted(self) {
  if (isSystemApp()) {
    // アプリ音声イベント
    window.finishVoiceApp = (text, error) => {
      if (!error) {
        _searchTextEvent(self, text);
      }
      self.recordingFlg = false;
    };

    // アプリ招待QR読み込みイベント
    window.finishQRApp = (text, error) => {
      if (!error) {
        checkInviteQr(text);
      }
    };
  }
}

function readInviteError(self) {
  const invite_error = storeActivate.storeGetActivateInviteError()
  if (invite_error) {
    switch (invite_error) {
      case 1: // 招待IDが存在しない
      case 2: // 招待期限切れ
        self.error = "この招待コードは無効です。既に使用済みですので、管理者に新しい招待を依頼してください。"
        break
      case 3: // 既に所属済の店舗
        self.error = "既に所属済の店舗です。"
        break
      case 93: // 既に従業員登録されている状態での従業員招待はエラー
        self.error = "すでに従業員として登録されている為、こちらの招待コードは利用できません。"
        break
      case 94: // 店舗アカウントでの招待は不可
        self.error = "店舗アカウントの為、別の店舗に参加することはできません。"
        break
      case 81: // 代理ログインでの企業作成は不可
        self.error = "代理ログイン中は、新規企業の作成はできません。"
        break
      case 82: // 店舗アカウントでの企業作成は不可
        self.error = "店舗アカウントの為、新規企業の作成はできません。"
        break
      case 91: // 所属企業追加エラー
      case 92: // 所属店舗追加エラー
      case 99: // 例外エラー
      default:
        self.invite_error = "店舗参加に失敗しました。しばらくしてから再度実施してください。";
    }
  }
  const invite_success = storeActivate.storeGetActivateInviteSuccess()
  if (invite_success) {
    self.success = invite_success
  }
  storeActivate.storeSetActivateInviteError();
  storeActivate.storeSetActivateInviteSuccess();
}

export async function readMenus(self) {
  await _readServiceLinks(self);
  // テスト用設定
  const associate = storeAuth.storeGetSelectedAssociate()
  const test_flg = associate ? associate.test_flg : 0
  if (!isEnvProd()) {
    self.test_menus = [
      {
        title: "新規企業登録ページ",
        to: "/auth/newcompany",
        icon: "mdi-office-building-plus-outline",
      },
      {
        title: "ファイルライブラリ",
        to: "/apptest/photo",
        icon: "mdi-image-album",
      },
      {
        title: "QR読み込み",
        to: "/apptest/qrread",
        icon: "mdi-microphone",
      },
      // {
      //   title: "AWS Polly",
      //   to: "/awstest/polly",
      //   icon: "mdi-text-to-speech",
      // },
      {
        title: "お知らせ投稿",
        to: "/test/notification",
        icon: "mdi-bell-plus-outline",
      },
      {
        title: "表示モード設定",
        to: "/test/displaymode",
        icon: "mdi-cellphone-cog",
      },
      {
        title: "プロファイル表示",
        to: "/test/displayprofile",
        icon: "mdi-card-bulleted",
      },
      {
        title: "アプリ音声テスト",
        to: "/test/appvoicetotext",
        icon: "mdi-microphone",
      },
      {
        title: "ムービー再生",
        to: "/test/movie-player",
        icon: "mdi-movie",
      },
      {
        title: "日付ピッカー",
        to: "/test/date-picker",
        icon: "mdi-calendar",
      },
      {
        title: "エラーログ出力",
        to: "/test/errorlog",
        icon: "mdi-math-log",
      },
      {
        title: "ユーザエージェント",
        to: "/test/ua",
        icon: "mdi-account-cash",
      },
      {
        title: "V-Manage利用申込",
        to: "/request/vmanage",
        icon: "mdi-cube-send",
      },
      {
        title: "メンテナンス",
        external: "/maintenance/" + (isDisplayModeSP() ? "sp/" : ""),
        icon: "mdi-sleep",
      },
      {
        title: "PDFプレビュー",
        to: "/test/pdf-preview",
        icon: "mdi-file-document",
      },
      {
        title: "バージョン同期確認",
        to: "/test/sync-version",
        icon: "mdi-sync",
      },
      {
        title: "リッチテキスト",
        to: "/test/rich-text",
        icon: "mdi-text-box-edit",
      },
      {
        title: "ブロックエディタ",
        to: "/test/block-editor",
        icon: "mdi-post",
      },
      {
        title: "リクエスト機能設定",
        to: "/test/company-spec-application",
        icon: "mdi-cog",
      },
    ];
  }
  if (isProxySigninAccount()) {
    self.test_menus = self.test_menus.filter(v => v.to != "/auth/newcompany")
  }
  if (!isProxySigninAccount()) {
    if (!isEnvProd() || (isEnvProd() && isSystemApp() && test_flg == 1)) {
      self.test_menus.push({
        title: "代理ログイン",
        to: "/test/proxysignin",
        icon: "mdi-account",
      })
    }
  }
}
// サービス一覧取得
export async function _readServiceLinks(self) {
  try {
    const store_links = storeAuth.storeGetServiceLinks()
    if (store_links.associate_id == storeAuth.storeGetKeyAsSelectedAssociate()) {
      let expired = new Date(store_links.created_at)
      expired.setMinutes(expired.getMinutes() + portal_config.home.service_reload_sec);
      if (new Date().getTime() < expired.getTime()) {
        _setMenusLinks(store_links.links)
        return
      }
    }

    // データ読み込み
    const ret = await apiParallelCall({
      company: { method: apiServiceLinksCompany },
      associate: { method: apiServiceLinksAssociate },
    });
    // データ解析
    let links = [];
    ret.company.forEach(v => {
      // データパース
      v.is_custom_link = v.link_cd.startsWith("_custom_");
      try {
        v.link_data = JSON.parse(v.link_data);
      } catch (err) {
        debugLog("link_data parse error", err);
        return
      }
      // ホームページURL未設定企業チェック
      if (v.link_cd == "corporate") {
        const homepage_url = storeAuth.storeGetSelectedAssociate("homepage_url");
        if (!homepage_url) return;
        v.link_data.external = { url: homepage_url };
      }
      // ユーザ利用不可チェック設定
      v.associate_available = true;
      const associate = ret.associate.find(a => a.link_cd == v.link_cd)
      if (associate) {
        v.associate_available = associate.available == 0
      }

      links.push(v);
    });
    storeAuth.storeSetServiceLinks(links)
    _setMenusLinks(links)
  } catch (err) {
    errorLog(getRouteName(), "initMyapp", err);
  }

  // メニュー設定判定
  async function _setMenusLinks(_links) {
    self.menus = _links.filter(v => {
      // 店舗の場合、企業での利用不可チェック
      if (!isUserHeadShop() && v.available != 0) return;
      // ユーザ利用不可チェック
      if (!v.associate_available) return;
      // ルートポリシー判定
      const policy = getRouterPolicy(v.link_cd)
      if (policy) {
        if (!isEnabledFunctionPolicy(policy)) {
          return
        }
      }
      // スマホデザインの場合、当面ファイル管理は利用不可
      if (isDisplayModeSP() && v.link_cd == "files") return false;
      // QRスキャン
      if (v.link_cd == "qrscan") {
        // アプリ＆通常アカウント専用
        if (!isSystemApp() || isUserShopAccount()) return false;
      }
      // V-Manage利用中企業の場合、未利用店舗はV-Manageアイコン非表示
      if (v.link_cd == "timemng") {
        if (isEnabledTimemng() && !isUserHeadShop()) {
          if (!isVManageUsingShop()) return
        }
      }
      // V-Manage追加SU申込
      if (v.link_cd == "timemng_add_setup") {
        if (!(isUserHeadShop() && isAddSetupRequest())) {
          // 本部かつ、追加SU申込状況が「営業承認済」以外の場合はアイコン非表示
          return false;
        }
      }
      // IPアドレス利用制限
      if (["timemng", "order"].includes(v.link_cd)) {
        if (storeAuth.storeGetShopIpAddressLimited() && !isUserHeadShop()) {
          return
        }
      }
      return true
    })
    _loadServiceIcons()
  }

  // アイコン画像読み込み
  async function _loadServiceIcons() {
    for (const link of self.menus.filter(
      (v) => v.is_custom_link && v.file_id
    )) {
      if (!self.loadicons[link.file_id]) {
        try {
          const result = await apiServiceLinksPreviewIcon(
            link.specific_key,
            link.file_id
          );
          self.loadicons[link.file_id] = result.data;
        } catch (err) {
          infoLog(getRouteName(), "_loadServiceIcons file_id:" + link.file_id, err);
        }
      }
    }
  }
}

// 通知読み込み
export async function readNotifications(self) {
  let notifications = await commonApi.apiNotificationSearch({ page: 1, limit: 3, });
  if (notifications.data.length == 0) {
    self.notifications = [
      {
        message: { message: "お知らせはありません" },
        inactive: true,
      },
    ];
  } else {
    notifications.data.map(v => v.message_text = v.message.created_by.toLowerCase() == 'v-manage' ? v.message.post_text : v.message.title)
    self.notifications = notifications.data
  }
}

// 録音制御
export function recording(self) {
  if (isSystemApp()) {
    if (!self.recordingFlg) {
      _appStartRecording(self);
    } else {
      _appCancelRecording(self);
    }
  } else {
    if (!self.recordingFlg) {
      _startRecording(self);
    } else {
      _finishRecording(self);
    }
  }
}

// アプリ音声
function _appStartRecording(self) {
  voiceApp.postMessage(""); // eslint-disable-line
  self.recordingFlg = true;
}
function _appCancelRecording(self) {
  if (self.recordingFlg) {
    voiceApp.postMessage("cancel"); // eslint-disable-line
    self.recordingFlg = false;
  }
}

// PC音声
function _startRecording(self) {
  if (!self.recordingFlg) {
    self.recognition.onresult = (event) => {
      _searchTextEvent(
        self,
        event.results[0][0].transcript.replaceAll(" ", "")
      );
    };
    self.recognition.onend = () => {
      if (self.recordingFlg) {
        self.recordingFlg = false;
      }
    };
    self.recordingFlg = true;
    self.recognition.start();
  }
}
function _finishRecording(self) {
  if (self.recordingFlg) {
    self.recognition.stop();
  }
}

// テキストイベント検知
function _searchTextEvent(self, text) {
  debugLog("text", text);
  // TODO:APIが出来上がるまでは内部判定
  let target = self.voices.find((v) =>
    v.words.find((word) => !text.indexOf(word))
  );
  if (target) {
    const link = storeAuth.storeGetSelectedLink(target.site);
    if (link) {
      portalMenuMove(self, link);
    }
  }
}

export default {
  initCreated,
  initMounted,
  readMenus,
  readNotifications,
  recording,
}
