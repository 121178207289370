export default
    [
        {
            path: '/test',
            component: () => import('@/components/test/Index'),
            children: [
                {
                    path: 'notification',
                    name: 'tests-notification',
                    component: () => import('@/components/test/SendNotification'),
                    meta: { title: '【テスト】お知らせ投稿' },
                }, {
                    path: 'displaymode',
                    name: 'tests-displaymode',
                    component: () => import('@/components/test/SetDisplayMode'),
                    meta: { title: '【テスト】表示モード設定' },
                }, {
                    path: 'displayprofile',
                    name: 'tests-displayprofile',
                    component: () => import('@/components/test/DisplayProfile'),
                    meta: { title: '【テスト】プロファイル表示' },
                }, {
                    path: 'appvoicetotext',
                    name: 'tests-app-voicetotext',
                    component: () => import('@/components/test/AppVoiceToText'),
                    meta: { title: '【テスト】アプリ音声テスト' },
                }, {
                    path: 'errorlog',
                    name: 'tests-errorlog',
                    component: () => import('@/components/test/ErrorLogger'),
                    meta: { title: '【テスト】エラーログ出力' },
                }, {
                    path: 'ua',
                    name: 'tests-ua',
                    component: () => import('@/components/test/UADisplay'),
                    meta: { title: '【テスト】ユーザエージェント' },
                }, {
                    path: 'proxysignin',
                    name: 'tests-proxysignin',
                    component: () => import('@/components/test/ProxySignin'),
                    meta: { title: '【テスト】代理ログイン' },
                }, {
                    path: 'movie-player',
                    name: 'tests-movie-player',
                    component: () => import('@/components/test/MoviePlayer'),
                    meta: { title: '【テスト】ムービー再生' },
                }, {
                    path: 'pdf-preview',
                    name: 'tests-pdf-preview',
                    component: () => import('@/components/test/PdfPreview'),
                    meta: { title: '【テスト】PDFプレビュー' },
                }, {
                    path: 'date-picker',
                    name: 'tests-date-picker',
                    component: () => import('@/components/test/DatePicker'),
                    meta: { title: '【テスト】日付ピッカー' },
                },{
                    path: 'sync-version',
                    name: 'tests-sync_version',
                    component: () => import('@/components/test/SyncVersion'),
                    meta: { title: '【テスト】バージョン同期確認' },
                },{
                    path: 'rich-text',
                    name: 'tests-rich-text',
                    component: () => import('@/components/test/RichText'),
                    meta: { title: '【テスト】リッチテキスト' },
                },{
                    path: 'block-editor',
                    name: 'tests-block-editor',
                    component: () => import('@/components/test/BlockEditor'),
                    meta: { title: '【テスト】ブロックエディタ' },
                },{
                    path: 'company-spec-application',
                    name: 'tests-company-spec-application',
                    component: () => import('@/components/test/CompanySpecApplication'),
                    meta: { title: '【テスト】リクエスト機能設定' },
                },
            ]
        },
        // {
        //     path: '/awstest',
        //     component: Index,
        //     children: [
        //         {
        //             path: 'polly',
        //             name: 'awstest-polly',
        //             component: AwsPolly,
        //             meta: { title: '【テスト】AWS Polly（テキスト⇒音声読み上げ）' },
        //         },
        //     ]
        // },
        {
            path: '/apptest',
            component: () => import('@/components/test/Index'),
            children: [
                {
                    path: 'photo',
                    name: 'apptest-photo',
                    component: () => import('@/components/test/AppFileStorage'),
                    meta: { title: '【APPテスト】フォトライブラリ' },
                },
                {
                    path: 'qrread',
                    name: 'apptest-qrread',
                    component: () => import('@/components/test/AppQRRead'),
                    meta: { title: '【APPテスト】QR読み込み' },
                },
            ]
        }
    ]

