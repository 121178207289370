import storeAuth from "@/helpers/store/storeAuthHelper"
import authApi from "@/helpers/auth/auth_api"
import { isServiceTimemng } from "@/helpers/environment"
import { errorLog } from "@/helpers/common/datadog"
import { isSystemApp, isSystemAndroidApp, getRouteName } from "@/helpers/common/common_util"
import { apiParallelCall } from '@/helpers/api_common'

// アカウント設定
export async function reloadAccountSpec() {
  if (!storeAuth.storeGetAccountId()) return
  try {
    // if (isServiceTimemng()) {
    //   // MEMO:当面設定はタイマネだけで利用
    //   const specs = await authApi.apiSpecAccountList()
    //   storeAuth.storeSetAccountSpecs(specs)
    // }
    const specs = await authApi.apiSpecAccountList()
    storeAuth.storeSetAccountSpecs(specs)
  } catch (err) {
    // MEMO:エラーは無視
    errorLog(getRouteName(), "reloadAccountSpec", err);
  }
}
function _getAccountSpec(spec_key) {
  const specs = storeAuth.storeGetAccountSpecs()
  if (specs) {
    const spec = specs.find(v => v.spec_key == spec_key)
    if (spec) return spec.spec_value
  }
}
async function _saveAccountSpec(spec_key, value) {
  let specs = storeAuth.storeGetAccountSpecs()
  if (specs) {
    let spec = specs.find(v => v.spec_key == spec_key)
    if (spec) {
      spec.spec_value = value
      storeAuth.storeSetAccountSpecs(specs)
      try {
        await authApi.apiSpecAccountUpdate([{
          spec_key: spec_key,
          spec_value: value,
          spec_value_type: spec.spec_value_type
        }])
      } catch (err) {
        // MEMO:エラーは無視
        errorLog(getRouteName(), "saveAccountSpecs" + spec_key, err);
      }
    }
  }
}

// 企業設定
export async function reloadCompanySpec() {
  if (!storeAuth.storeGetAccountId()) return
  try {
    if (isServiceTimemng()) {
      const ret = await apiParallelCall({
        task_schedule_ui_ver: { method: authApi.apiSpecCompanyGetTaskScheduleUiVer },
        ref_other_daily_reports: { method: authApi.apiSpecCompanyGetRefOtherDailyReports },
        translate: { method: authApi.apiSpecCompanyGetTranslate },
        use_ranking: { method: authApi.apiSpecCompanyGetUseRanking },
        file_storage_period: { method: authApi.apiSpecCompanyGetFileStoragePeriod },
        use_application: { method: authApi.apiSpecCompanyGetUseApplication },
      });
      const specs = []
      specs.push(...ret.task_schedule_ui_ver)
      specs.push(...ret.ref_other_daily_reports)
      ret.translate.spec_key = 'TranslateMultiLang'
      specs.push(ret.translate)
      specs.push(ret.use_ranking)
      specs.push(ret.file_storage_period)
      specs.push(ret.use_application)
      storeAuth.storeSetCompanySpecs(specs)
    } else {
      const ret = await apiParallelCall({
        use_application: { method: authApi.apiSpecCompanyGetUseApplication },
      });
      const specs = []
      specs.push(ret.use_application)
      storeAuth.storeSetCompanySpecs(specs)
    }
  } catch (err) {
    // MEMO:エラーは無視
    errorLog(getRouteName(), "reloadCompanySpec", err);
  }
}
export function setCompanySpecsTaskScheduleUiVer(value) {
  _setCompanySpecsStore(SpecKeyTaskScheduleUIVersion, value)
}
export function setCompanySpecsRefOtherDailyReports(value) {
  _setCompanySpecsStore(SpecKeyRefOtherDailyReports, value)
}
export function setCompanySpecsTranslate(value) {
  _setCompanySpecsStore(SpecKeyTranslate, value)
}
export function setCompanySpecsUseRanking(value) {
  _setCompanySpecsStore(SpecKeyUseRanking, value)
}
export function setCompanySpecsFileStoragePeriod(value) {
  _setCompanySpecsStore(SpecKeyFileStoragePeriod, value)
}
export function setCompanySpecsUseApplication(value) {
  _setCompanySpecsStore(SpecKeyUseApplication, value)
}
function _setCompanySpecsStore(spec_key, value) {
  let specs = storeAuth.storeGetCompanySpecs()
  let spec = specs.find(v => v.spec_key == spec_key)
  if (spec) {
    specs.map(v => {
      if (v.spec_key == spec_key) v.spec_value = value
    })
  } else {
    specs.push({
      spec_key: spec_key,
      spec_value: value,
    })
  }
  storeAuth.storeSetCompanySpecs(specs)
}
export function getCompanySpecsTaskScheduleUiVer() {
  return _getCompanySpecsStore(SpecKeyTaskScheduleUIVersion)
}
export function getCompanySpecsRefOtherDailyReports() {
  return _getCompanySpecsStore(SpecKeyRefOtherDailyReports)
}
export function getCompanySpecsTranslate() {
  return _getCompanySpecsStore(SpecKeyTranslate)
}
export function getCompanySpecsUseRanking() {
  return _getCompanySpecsStore(SpecKeyUseRanking, { head: true, shop: true })
}
export function getCompanySpecsFileStoragePeriod() {
  return _getCompanySpecsStore(SpecKeyFileStoragePeriod, { routine: "no_transfer", routine_sub: "no_transfer", emergency: "no_transfer", personal: "no_transfer" })
}
export function getCompanySpecsUseApplication() {
  return _getCompanySpecsStore(SpecKeyUseApplication, 0)
}
function _getCompanySpecsStore(spec_key, def = 1) {
  let specs = storeAuth.storeGetCompanySpecs()
  let spec = specs.find(v => v.spec_key == spec_key)
  return spec ? spec.spec_value : def
}

// 直近タスクの通知（ビープ音）
export function getAccountSpecBeepBeforeTaskDeadline() {
  return _getAccountSpec("BeepBeforeTaskDeadline")
}
export async function saveAccountSpecBeepBeforeTaskDeadline(value) {
  return await _saveAccountSpec("BeepBeforeTaskDeadline", value)
}
export function getBeepBeforeTaskDeadlines() {
  return BeepBeforeTaskDeadlines
}
export function playBeepBeforeTaskDeadline(key) {
  if (isServiceTimemng()) {
    const beep = BeepBeforeTaskDeadlines.find(v => v.key == key)
    if (beep) {
      // タスクビープ音再生
      if (isSystemApp() && !isSystemAndroidApp()) {
        // iOS端末のみ有効
        document.getElementById("audioSource").src = require("@/assets/beep/" + beep);
        document.getElementById("audioPlayback").load();
        document.getElementById("audioPlayback").play();
      }
    }
  }
}
// 直近タスクの通知（プッシュ通知）
export function getAccountSpecReceivePushNotification() {
  return _getAccountSpec("ReceivePushNotification")
}
export async function saveAccountSpecReceivePushNotification(value) {
  return await _saveAccountSpec("ReceivePushNotification", value)
}

export default {
  // アカウント設定
  reloadAccountSpec,

  // 企業設定
  reloadCompanySpec,
  setCompanySpecsTaskScheduleUiVer,
  setCompanySpecsRefOtherDailyReports,
  setCompanySpecsTranslate,
  setCompanySpecsUseRanking,
  setCompanySpecsFileStoragePeriod,
  getCompanySpecsTaskScheduleUiVer,
  getCompanySpecsRefOtherDailyReports,
  getCompanySpecsTranslate,
  getCompanySpecsUseRanking,
  getCompanySpecsFileStoragePeriod,

  // 直近タスクの通知（ビープ音）
  getAccountSpecBeepBeforeTaskDeadline,
  saveAccountSpecBeepBeforeTaskDeadline,
  getBeepBeforeTaskDeadlines,
  playBeepBeforeTaskDeadline,

  // 直近タスクの通知（プッシュ通知）
  getAccountSpecReceivePushNotification,
  saveAccountSpecReceivePushNotification,
}

const BeepBeforeTaskDeadlines = [
  { key: 1, text: "通知音１", value: "one35.mp3" },
  { key: 2, text: "通知音２", value: "one03.mp3" },
  { key: 3, text: "通知音３", value: "one18.mp3" },
]

const SpecKeyTaskScheduleUIVersion = 'TaskScheduleUIVersion';
const SpecKeyRefOtherDailyReports = 'SearchOtherStoresDailyReports'
const SpecKeyTranslate = 'TranslateMultiLang'
const SpecKeyUseRanking = 'ExecutionRateBoard'
const SpecKeyFileStoragePeriod = 'FileStoragePeriod'
const SpecKeyUseApplication = 'UseApplicationStatus'