<template>
  <div>
    <CommonHeader :parents="parents" />
    <AppAlert
      v-if="notifications_for_bar"
      :notifications_for_bar="notifications_for_bar"
    />
    <div class="setting-list contents">
      <MessageViewer :success="success" :error="error" />
      <div class="d-flex">
        <div class="home-side">
          <div class="notification-nav">
            <div class="notification-nav-inner">
              <div class="font-weight-bold">
                <v-icon size="20" color="#666">mdi-bell</v-icon>
                <span class="ml-2">通知</span>
              </div>
              <div class="mt-4">
                <div
                  v-for="(item, i) in service_types"
                  class="notification-nav-item"
                  :key="i"
                >
                  <button @click="clickNotification(item)">
                    <span>{{ item.text }}</span>
                    <!-- 件数、新着サイン -->
                    <span
                      v-if="getServiceStatsNewCount(item)"
                      class="notification-nav-item-badge-new"
                    >
                      新着{{ getServiceStatsNewCount(item) }}
                    </span>
                    <!-- <span v-else-if="getServiceStatsOldCount(item)" class="notification-nav-item-badge-count">
                      {{ getServiceStatsOldCount(item) }}
                    </span> -->
                  </button>
                </div>
              </div>
              <!-- 通知が0件の場合 -->
              <div class="mt-4" style="font-size: 12px">
                <div>新着：過去72時間以内の通知</div>
              </div>
            </div>
          </div>
          <div class="home-sso mt-6">
            <div class="underline-title font-weight-bold">SSO設定</div>
            <div class="mt-4">
              この設定を行うことで、他のアプリケーションやサービスとの連携がスムーズになり、F-Portalをより一層便利に利用していただけます。
            </div>
            <div class="mt-2">
              <button @click="pageMove('linked_services')">SSO設定</button>
            </div>
          </div>
        </div>
        <div class="home-archive">
          <div class="card">
            <div class="card-head home-card-head">
              <div class="card-head-title font-weight-bold">サービス</div>
              <div class="card-head-link">
                <button @click="pageMove('/myapp')">すべてのサービス</button>
              </div>
            </div>
            <div class="card-body home-services">
              <div
                v-for="menu in menus"
                :key="menu.link_cd"
                class="home-service-item"
                @click="portalMenuMove(this, menu)"
              >
                <div class="home-service-icon mr-2">
                  <template v-if="menu.is_custom_link">
                    <img
                      v-if="menu.file_id"
                      :src="getAttachedImageSrc(loadicons[menu.file_id])"
                    />
                    <img
                      v-else
                      :src="getPortalServiceImagePath('noicon.png')"
                    />
                  </template>
                  <template v-else>
                    <img
                      v-if="menu.link_data.img"
                      :src="getPortalServiceImagePath(menu.link_data.img)"
                    />
                    <i v-else :class="'mdi ' + menu.link_data.icon"></i>
                  </template>
                </div>
                <div class="home-service-name">
                  {{ menu.link_name }}
                  <span
                    v-if="menu.link_cd == is_chat && existUnreadChat"
                    class="notification-nav-item-badge-new font-weight-regular"
                  >
                    未読
                  </span>
                </div>
              </div>
              <div
                v-for="menu in test_menus"
                :key="menu.link_cd"
                class="home-service-item"
                @click="pageMove(menu)"
              >
                <div class="home-service-icon mr-2">
                  <i :class="'mdi ' + menu.icon" style="color: green"></i>
                </div>
                <div class="home-service-name">(テスト){{ menu.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="microphone">
        <button class="microphone-btn home-microphone-btn" @click="clickRecording()">
          <i v-if="!recordingFlg" class="mdi mdi-microphone"></i>
          <img
            v-else
            src="@/assets/microphone_input.png"
            style="width: 100%"
            class="microphone-btn-img"
          />
        </button>
      </div> -->
    </div>
    <ExecutingOverlay ref="execute" />
  </div>
</template>

<script>
import CommonHeader from "@/components/service/PcHeader";
import MessageViewer from "@/components/common/MessageViewerExtra";
import AppAlert from "@/components/portal/PcAppAlert.vue";
import ExecutingOverlay from "@/components/common/ExecutingOverlay";

export default {
  components: { CommonHeader, MessageViewer, AppAlert, ExecutingOverlay },
  props: { parents: Object },
  setup() {
    const { getEnabledHeaderLinks } = require("@/helpers/service_util");
    const {
      getPortalServiceImagePath,
      portalMenuMove,
    } = require("@/helpers/portal/portal_util");
    const {
      pageMove,
      isSystemApp,
      getLogoFPortal,
      formatDateStrByDBTime,
      getAttachedImageSrc,
      getChatUnreadCountByStore,
    } = require("@/helpers/common/common_util");
    const {
      readNotificationsListForBar,
      isLinkNotification,
      execLinkNotification,
      getNotificationServiceTypes,
      readNotificationStats,
    } = require("@/helpers/common/notifications");
    const {
      initCreated,
      initMounted,
      readMenus,
      readNotifications,
      recording,
    } = require("./home");
    return {
      getEnabledHeaderLinks,
      getPortalServiceImagePath,
      portalMenuMove,
      pageMove,
      isSystemApp,
      getLogoFPortal,
      formatDateStrByDBTime,
      getAttachedImageSrc,
      getChatUnreadCountByStore,
      readNotificationsListForBar,
      isLinkNotification,
      execLinkNotification,
      getNotificationServiceTypes,
      readNotificationStats,
      initCreated,
      initMounted,
      readMenus,
      readNotifications,
      recording,
    };
  },
  data: () => ({
    success: "",
    error: "",

    menus: [],
    loadicons: [],
    test_menus: [],
    notifications: [],
    notifications_for_bar: null,
    is_mytask_navi: false,
    is_chat: "chat",

    service_types: [],
    stats: [],

    // 録音用
    recordingFlg: false,
    recognition: null,

    voices: [
      {
        site: "foodsch",
        words: [
          "フーチャン",
          "ふーちゃん",
          "フーチャネ",
          "ふーちゃね",
          "フーズチャネル",
          "ふーずちゃねる",
          "フードチャネル",
          "ふーどちゃねる",
          "フードチャンネル",
          "ふーどちゃんねる",
          "FoodCHANNEL",
        ],
      },
      { site: "order", words: ["発注", "はっちゅう", "ハッチュウ"] },
    ],
  }),
  async created() {
    await this.initCreated(this);
    this.parents.header_links = this.getEnabledHeaderLinks("home"); // eslint-disable-line
    this.readMenus(this);
    this.service_types = [
      { key: "", text: "すべての通知" },
      ...this.getNotificationServiceTypes(),
    ];
    this.stats = await this.readNotificationStats(true);
    await this.readNotifications(this);
    this.notifications_for_bar = await this.readNotificationsListForBar();
  },
  async mounted() {
    await this.initMounted(this);
  },
  methods: {
    clickRecording() {
      this.recording(this);
    },
    isExistStats() {
      return this.stats.filter((v) => v.count > 0).length > 0;
    },
    isExistServiceStats(item) {
      return (
        this.getServiceStatsNewCount(item) || this.getServiceStatsOldCount(item)
      );
    },
    getServiceStatsNewCount(item) {
      const count = this.getServiceStatsCount(item, "new");
      if (count) return count > 999 ? "999+" : count;
    },
    getServiceStatsOldCount(item) {
      const count = this.getServiceStatsCount(item, "old");
      if (count) return count > 999 ? "999+" : count;
    },
    getServiceStatsCount(item, oldnew) {
      if (!item.key) return;
      const stats = this.stats.find(
        (v) => v.oldnew == oldnew && v.service_type == item.key && v.count > 0
      );
      if (stats) return stats.count;
    },

    // 通知
    clickNotification(item) {
      let url = "/notification";
      if (item.key) url += "?type=" + item.key;
      this.pageMove(url);
    },
  },
  computed: {
    existUnreadChat() {
      const unread = this.getChatUnreadCountByStore();
      return unread > 0;
    },
  },
};
</script>

<style scoped>
.v-application {
  background-color: unset !important;
}

.v-application ul {
  padding-left: 0 !important;
}

.v-application a {
  color: inherit;
}

.home-notification-item.nolink:hover {
  cursor: default !important;
}

.home-side .notification-nav {
  position: relative;
  max-width: unset;
  min-width: unset;
}
</style>
